import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import Carousel from 'nuka-carousel/lib/carousel';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ProductListItem from 'components/ProductListItem';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { findIdByUrl } from '../../utils/findIdByUrl';
import { arrayChunk } from '../../utils/functions';
import { ProductProps } from './models';
import { Types } from './modelTypes';

import './Product.scss';

const Product: FC<{ data: ProductProps }> = ({
  data: {
    product: {
      id: idProduct,
      pageLogo,
      title: titleProduct,
      titleFlavor,
      titleProductList,
      productLegalText,
      subtitleStores,
      subtitleStoresComplement,
      stores,
      subtitle: subTitleProduct,
      storesLegalText,
      description,
      imageDesktop,
      imageMobile,
      image,
      urls,
      backgroudRecipesMobile,
      backgroudRecipesDesktop,
      seo,
      store,
      subBanner,
      subtitleRecipes,
      recipesOrdered,
      recipesLegalText,
      videoYoutubeId,
      videoPoster,
      ctaRecipes: { name, url },
      cssExtraClass,
      buttonShowMore,
      pixel,
    },
    allProduct: { nodes },
    productList: { productsSliderTitleComplement, productsSliderTitle },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const {
    title: bannerTitle,
    subtitle,
    legalText,
    imageDesktop: bannerDesktop,
    imageMobile: bannerMobile,
  } = subBanner;

  const videoId = videoYoutubeId && videoPoster ? findIdByUrl(videoYoutubeId) : undefined;

  const recipesOrder =
    recipesOrdered && recipesOrdered.length > 0
      ? recipesOrdered.sort((a, b) => a.order - b.order)
      : null;

  const nodesOrdered = nodes[0].displayOrder
    ? nodes.sort((a, b) => a.displayOrder - b.displayOrder)
    : nodes;

  const prodId = idProduct;

  const indexOfProduct = nodesOrdered.findIndex((object) => object.id === prodId);

  nodesOrdered.splice(indexOfProduct, 1);

  const eventData = { eventType: 'view_item', tagData: { value: seoTitle } };

  return (
    <Layout headerTransparent alterLogo={pageLogo || null} {...{ pixel }}>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebSite"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        imageurl={image}
        tagEventData={eventData}
      />
      <div className="mx-auto product__container position-relative product overflow-hidden">
        <div className="row mx-0">
          <div className="col px-0">
            <section className={`product__banner position-relative clr-${cssExtraClass}`}>
              <Image
                imageData={imageDesktop}
                alt={imageDesktop.altText}
                className={`d-none d-md-block product__banner--desk clr-${cssExtraClass}`}
              />
              <Image
                imageData={imageMobile}
                alt={imageMobile.altText}
                className={`d-block d-md-none product__banner--mobile clr-${cssExtraClass}`}
              />
              <div
                className={`product__text position-absolute container-fluid px-md-0 clr-${cssExtraClass}`}
              >
                <h1 className="product__text--title p-0">
                  {titleProduct}
                  <b className={`product__text--subtitle clr-${cssExtraClass}`}>{titleFlavor}</b>
                </h1>

                {subTitleProduct ? (
                  <h2 className={`product__text--subtitleFlavor clr-${cssExtraClass}`}>
                    {subTitleProduct}
                  </h2>
                ) : null}
                <a href={store.url} className="btn btn-product" target="_blank" rel="noreferrer">
                  {store.name}
                </a>
              </div>

              <div
                className={`product__bnnContainer position-relative d-block d-md-none color clr-${cssExtraClass}`}
              >
                <DangerouslySetInnerHtml
                  html={description}
                  element="div"
                  className="product__bnnContainer--description position-relative pt-3"
                />
                <DangerouslySetInnerHtml
                  html={productLegalText}
                  element="div"
                  className="product__bnnContainer--legals legal position-relative"
                />
              </div>
              <div
                className={`product__bnnContainer position-relative d-none d-md-block bg-transparent color clr-${cssExtraClass}`}
              >
                <DangerouslySetInnerHtml
                  html={description}
                  element="div"
                  className="product__bnnContainer--description position-relative pt-3"
                />
                <DangerouslySetInnerHtml
                  html={productLegalText}
                  element="div"
                  className={`product__bnnContainer--legals legal position-relative clr-${cssExtraClass}`}
                />
              </div>
              <div className="product__shop position-relative text-center mb-md-0">
                <h3 className="product__shop-title mx-auto m-0">{subtitleStores}</h3>
                <h3 className="product__shop-subtitle mt-md-2">{subtitleStoresComplement}</h3>
                <div
                  className={`product__shops d-flex justify-content-start flex-wrap align-items-center justify-content-md-center flex-md-nowrap mt-5 clr-${cssExtraClass}`}
                >
                  {stores.map(
                    ({ image: imageProduct, link: { name: nameImage, url: urlImage, target } }) => (
                      <a
                        href={urlImage}
                        key={urlImage}
                        target={target || ''}
                        className="product__shops--shop text-decoration-none d-flex flex-column-reverse justify-content-center align-items-center"
                      >
                        <h3 className="py-2">{nameImage}</h3>

                        <Image
                          imageData={imageProduct}
                          alt={imageProduct.altText}
                          className="mx-auto d-block"
                        />
                      </a>
                    )
                  )}
                </div>
                <DangerouslySetInnerHtml
                  html={storesLegalText}
                  element="div"
                  className="legend-product mx-auto"
                />
              </div>
              <div className={`bnnPantera position-relative clr-${cssExtraClass}`}>
                <div
                  className={`bnnPantera__header position-absolute container-fluid text-center pt-4 clr-${cssExtraClass}`}
                >
                  <h3 className="bnnPantera__header--title d-none d-md-block m-0">{bannerTitle}</h3>
                  <h3 className="bnnPantera__header--subtitle d-none d-md-block m-0">{subtitle}</h3>
                </div>
                <DangerouslySetInnerHtml
                  html={legalText}
                  element="div"
                  className={`bnnPantera__footer position-absolute container-fluid text-center p-2 lh-sm clr-${cssExtraClass}`}
                />
                <Image
                  imageData={bannerDesktop}
                  alt={bannerDesktop.altText}
                  className="bnnPantera--desk d-none d-md-block"
                />
                <Image
                  imageData={bannerMobile}
                  alt={bannerMobile.altText}
                  className="bnnPantera--mobile d-md-none d-block"
                />
              </div>
            </section>
            <section
              className={`container-fluid bnn__video ${
                recipesOrder ? '' : 'bnn__video--no-recipes'
              } position-relative color clr-${cssExtraClass}`}
            >
              {videoId && videoPoster ? <YouTubeLazyLoaded {...{ videoId, videoPoster }} /> : null}
            </section>
          </div>
        </div>

        {recipesOrder ? (
          <section className={`slider__recipes position-absolute text-center clr-${cssExtraClass}`}>
            <h3 className="slider__recipes--title text-center text-md-start lh-1">
              {subtitleRecipes}
            </h3>

            <div className="container-fluid d-none d-md-block desktop slider__recipes--container">
              <div className="row justify-content-center align-items-center flex-column align-items-md-start flex-md-row justify-content-md-center">
                {recipesOrder.map((orderRec) =>
                  orderRec.recipe.map(({ title, summary, image: imageRecipe, urlrecipe }) => {
                    const altTitle = title || '';
                    const imgAlt = imageRecipe !== undefined ? imageRecipe.altText : altTitle;
                    const imageName = imgAlt?.split('/').pop();

                    return (
                      <div key={title} className="recipeItem my-2 p-0 overflow-hidden">
                        <Image
                          imageData={imageRecipe}
                          alt={imageName}
                          className="recipeItem__img"
                        />
                        <DangerouslySetInnerHtml
                          html={title}
                          element="p"
                          className="recipeItem__title text-start"
                        />
                        <DangerouslySetInnerHtml
                          html={summary}
                          element="div"
                          className="recipeItem__instructions text-start"
                        />
                        <a
                          href={urlrecipe}
                          className="btn btn-recipe"
                          rel="noreferrer"
                          aria-label={Types.btn}
                        >
                          {buttonShowMore}
                        </a>
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div className="container-fluid d-md-none mobile slider__recipes--container">
              <div className="row slider__recipes--mobile">
                <Carousel wrapAround>
                  {recipesOrder.map((orderRec) =>
                    orderRec.recipe.map(({ title, summary, image: imageRecipe, urlrecipe }) => {
                      const altTitle = title || '';
                      const imgAlt = imageRecipe !== undefined ? imageRecipe.altText : altTitle;
                      const imageName = imgAlt?.split('/').pop();

                      return (
                        <div
                          key={title}
                          className="recipeItem recipeItem__mobile p-0 overflow-hidden mx-auto"
                        >
                          <Image
                            imageData={imageRecipe}
                            alt={imageName}
                            className="recipeItem__img"
                          />
                          <DangerouslySetInnerHtml
                            html={title}
                            element="p"
                            className="recipeItem__title text-start"
                          />
                          <DangerouslySetInnerHtml
                            html={summary}
                            element="div"
                            className="recipeItem__instructions text-start"
                          />
                          <a href={urlrecipe} className="btn btn-recipe" rel="noreferrer">
                            {buttonShowMore}
                          </a>
                        </div>
                      );
                    })
                  )}
                </Carousel>
              </div>
            </div>

            <DangerouslySetInnerHtml
              html={recipesLegalText}
              element="div"
              className="slider__recipes--legals legal text-md-start"
            />
            <Link to={url} className="slider__recipes--btn d-none d-md-block">
              <button type="button" className="position-relative bg-transparent btn-recipe">
                {name}
              </button>
            </Link>
          </section>
        ) : null}

        {recipesOrder ? (
          <div className={`recipes__banner position-relative clr-${cssExtraClass}`}>
            <Image
              imageData={backgroudRecipesDesktop}
              alt={backgroudRecipesDesktop.altText}
              className="d-none d-md-block recipes__banner--desktop-1"
            />
            <Image
              imageData={backgroudRecipesMobile}
              alt={backgroudRecipesMobile.altText}
              className="d-block d-md-none product__banner--mobile"
            />
          </div>
        ) : null}
        <div className="row mx-0 product__footerSlider">
          <div className="col px-0">
            <section
              className={`product__footerSlider--items position-relative py-2 ${
                recipesOrder ? '' : 'product__footerSlider--no-recipes'
              }`}
            >
              <div className="product__footerSlider--title text-md-start">
                <h4 className="title lh-2">{productsSliderTitle}</h4>
                <h4 className="subtitle mt-1">{productsSliderTitleComplement}</h4>
                <DangerouslySetInnerHtml
                  html={productLegalText}
                  element="div"
                  className="legal text-start d-none d-md-block"
                />
              </div>
              <div className="pb-4 slider__products">
                <div className="d-none d-md-block other-products">
                  {arrayChunk(nodesOrdered, 6).map((row) => {
                    const { id } = row[0];

                    return (
                      <div key={id}>
                        <ProductListItem items={row} cols={1} showTitle />
                      </div>
                    );
                  })}
                </div>
                <Carousel wrapAround className="d-block d-md-none slider__products--mobile">
                  {arrayChunk(nodesOrdered, 1).map((row) => {
                    const { id } = row[0];

                    return (
                      <div key={id}>
                        <ProductListItem items={row} cols={1} {...{ titleProductList }} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </section>
          </div>
        </div>
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $lang: String) {
    product(url: { eq: $url }) {
      id
      title
      titleFlavor
      subtitle
      buttonShowMore
      store {
        name
        url
      }
      pageLogo {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      titleProductList
      description
      productLegalText
      recipesLegalText
      subtitleStores
      subtitleStoresComplement
      cssExtraClass
      videoYoutubeId
      videoYoutubeTitle
      videoPoster {
        childImageSharp {
          gatsbyImageData
        }
      }
      stores {
        link {
          target
          name
          url
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      storesLegalText
      subtitleRecipes
      recipesOrdered {
        structure
        order
        recipe {
          title
          summary
          urlrecipe: url
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ctaRecipes {
        name
        url
      }
      subBanner {
        title
        subtitle
        legalText
        imageDesktop {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        imageMobile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      description
      store {
        url
        name
      }
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      backgroudRecipesDesktop {
        childImageSharp {
          gatsbyImageData
        }
      }
      imageDesktop {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      backgroudRecipesMobile {
        childImageSharp {
          gatsbyImageData
        }
      }
      imageMobile {
        childImageSharp {
          gatsbyImageData
        }
      }
      pixel {
        nameEvent
        contentId
        contentName
        contentType
        contentValue
        contentCurrency
      }
    }
    allProduct {
      nodes {
        id
        displayOrder
        title
        subtitle
        titleFlavor
        url
        storesLegalText
        cssExtraClass
        productListTitle
        productExtraInfo: productListExtraInfo
        image {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
    productList {
      productsSliderTitle
      productsSliderTitleComplement
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Product;
